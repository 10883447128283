import axios from 'axios';
import { TimeOut } from './server';
import { message } from 'antd';

export const error = (response: any) => {
  let newError = String(response)
  if (typeof(response) === "object") {
    message.error(newError);
  throw new Error(newError);
  }
  message.error(response);
  throw new Error(response);
};

export const server = axios.create({
  // baseURL:'http://some-domain.com/api/',
  timeout: TimeOut,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept': '*/*',
  }
});

// 添加请求拦截器
server.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
server.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export const wrapServer = (opt: any) => {
  return server
    .request({
      method: 'post',
      ...opt,
    })
    .then(response => {
      const data = response.data;
      if (data.code === 200 || data.status.code === 200 || data.status.code === '200') {
        return data;
      } 
      if (data.status.code === 100004 || data.status.code === '100004') {
        window.location.href = '/login';
      }
      else {
        return Promise.reject(response.data.status.msg);
      }
    })
    .catch(info => {
      return error(info);
    });
};

export const wrapServer1 = (opt: any) => {
  return server
    .request({
      method: 'post',
      ...opt,
    })
    .then(response => {
      const data = response.data;
      if (data.meta.code === 6666) {
        return data;
      } 
      else {
        return Promise.reject(response.data.status.msg);
      }
    })
    .catch(info => {
      return error(info);
    });
};


export const wrapServer2 = (opt: any) => {
  return server
    .request({
      method: 'post',
      ...opt,
    })
    .then(response => {
      const data = response.data;
      return data
    })
    .catch(info => {
      return {}
      return error(info);
    });
};