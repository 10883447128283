const API = '/souche/api';
const SEAL = '/chain';
const SOCKET = '';
const DNCAPI = '/api/coin';
const JYS = '/exchange-api';
const UPLOAD = '/upload';
const SPG = '/ncp';
// 生产环境
// const WS = `ws://34.92.89.104:8080`;
// 测试环境
const WS = `ws:192.168.50.232:8080`;

const api = {
  // login: `${API}/login`,
  // logout: `${API}/logout`,
  imgUrl: `${API}/captcha.jpg`,
  // register: `${API}/user-info`,
  sysRole: `${API}/sys-role`,
  roleStatus: `${API}/sys-role/role/statues`,
  getRole: `${API}/sys-role`,
  changePerm: `${API}/rolePerm/update`,
  // getUserInfo: `${API}/user-info`,
  changeRoleType: `${API}/user-role/update`,
  useInfoStatus: `${API}/user-info/statues`,
  queryProductList: `${API}/assets/essentials`,
  queryProductDetails: `${API}/assets/get`,
  queryRepayMent: `${API}/repayment`,
  queryTransferList: `${API}/assets/transfer`,
  queryOverdueList: `${API}/repayment/get`,
  queryAssetsList: `${API}/assets/get`,
  queryRepeatList: `${API}/assets/duplicate`,
  queryTransferRecord: `${API}/assets/duplicate`,
  queryChart1: `${API}/analyse/chart-1`,
  queryText: `${API}/analyse`,
  queryChart2: `${API}/analyse/chart-2`,
  queryChart3: `${API}/analyse/chart-3`,
  queryChart4: `${API}/analyse/chart-4`,
  queryChart5: `${API}/analyse/chart-5`,
  queryChart6: `${API}/analyse/chart-6`,
  queryWarning: `${API}/warning`,
  queryWarningQuery: `${API}/warning/query`,
  queryEmailList: `${API}/user-messaging/user-email-list`,
  queryEmailWarningClose: `${API}/user-messaging/close-email-warning`,
  queryEmailWarningOpen: `${API}/user-messaging/open-email-warning`,
  changePassword: `${API}/reset-pwd`,
  createRole: `${API}/sys-role`,
  createUser: `${API}/user-info`,
  queryMailContext: `${API}/mail-context`,
  userGetRole: `${API}/user-role/role`,
  getJournalList: `${API}/logs`,
  getUrl: `${API}/url`,
  
  queryDataList: `${API}/d2d/native`,
  getonlineHash: `${SEAL}/summary`,
  assetsExport: `${API}/assets/getExport`,
  duplicateExport: `${API}/assets/duplicateExport`,
  queryOption: `${API}/assets/products`,
  download: `${API}/attachment/download`,


  depth: `${SOCKET}/depth`,
  // login: `${SOCKET}/fast/token`,
  getKline: `${SOCKET}/fast/kline`,
  getAggtrade: `${SOCKET}/fast/aggtrade`,
  getDepth: `${SOCKET}/fast/depth`,
  // socketStream: `ws://34.92.89.104:8080/stream`,
  socketStream: `${WS}/stream`,
  // socketStream: `ws://demoexchange.cryptorelay.io/stream`,
  querySymbols: `${SOCKET}/fast/symbols`,
  limit: `${SOCKET}/fast/limit`,
  assets: `${SOCKET}/fast/assets`,
  queryOpenOrders: `${SOCKET}/fast/open_orders`,
  queryHistoryOrders:`${SOCKET}/fast/history_orders`,
  userToken: `${SOCKET}/fast/user_stream`,
  cancelOrder: `${SOCKET}/fast/cancel`,
  getChartData: `${DNCAPI}/web-coinrank?page=1&type=-1&pagesize=100&webp=1`,

  login: `${JYS}/fore/login`,
  logout: `${JYS}/logout`,
  register: `${JYS}/user/register`,
  querySymbolDetail: `${SOCKET}/fast/tickers`,
  getUserInfo: `${JYS}/system/user/my-info`,
  getAllCountries: `${JYS}/identity/get-all-countries`,
  insetUserInfo: `${JYS}/identity/update`,
  getPreUpdate: `${JYS}/identity/pre-update`,
  changeLoginPassword: `${JYS}/system/user/update-login-password`,
  changFundPassword: `${JYS}/system/user/update-fund-password`,
  getRechargeList: `${JYS}/fund-request/list`,
  getCapitalList: `${JYS}/user-fund/list`,
  checkAccount: `${JYS}/system/user/check-account`,
  getProjectForeList: `${JYS}/fore-project/fore-list`,
  getProjectDetail: `${JYS}/fore-project/detail`,
  buyProject: `${JYS}/fore-project/buy`,
  queryAllSymbolList: `${JYS}/symbol/coin-condition-symbol`,
  upload: `${UPLOAD}/upload`,
  getMyInvitation: `${JYS}/invitation-record/fore/my-list`,
  cofirmCash: `${JYS}/fore/withdraw`,

  innovativeProjectList: `${JYS}/fore-project/fore-list`,
  Redeem: `${JYS}/user-fund/redeem`,
  getProjectInnovateForeList: `${JYS}/fore-creation-project/fore-list`,
  getProjectInnovateDetail: `${JYS}/fore-creation-project/detail`,
  buyInnovateProject: `${JYS}/fore-creation-project/buy`,
  getProjectForeListEn: `${JYS}/fore-project-EN/fore-list`,
  getProjectInnovateForeListEn: `${JYS}/fore-creation-project-en/fore-list`,
  getProjectDetailEn: `${JYS}/fore-project-EN/detail`,
  getProjectInnovateDetailEn: `${JYS}/fore-creation-project-en/detail`,

  getAllCoin: `${JYS}/user-fund/all-coins`,
  getAddress: `${JYS}/user-fund/get-wallet-addr`,

  querySMSList: `${JYS}/sms/send`,
  forgetPassword: `${JYS}/user/password/mobile_reset`,

  getAllProjectW: `${JYS}/my-bought-record/get-zh-stable-projects`,
  getAllProjectC: `${JYS}/my-bought-record/get-zh-creation-projects`,
  getAllProjectWE: `${JYS}/my-bought-record/get-en-stable-projects`,
  getAllProjectCE: `${JYS}/my-bought-record/get-en-creation-projects`,

  getStableList: `${JYS}/my-bought-record/stable-list`,
  getCreationList: `${JYS}/my-bought-record/creation-list`,


  getBlock: `${SEAL}/blocks`,
  getEpochs: `${SEAL}/txs`,
  getCurrentBlock: `${SEAL}/block/currentBlock`,
  getBlockDetail: `${SEAL}/block`,
  getProjectList: `${SPG}/products`,
  getTransactionDetail: `${SEAL}/tx`,
  getOrderList: `${SPG}/orders`,
  getOriginsList: `${SPG}/origins`,
}

export default api;