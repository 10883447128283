import React, { Component } from 'react';
// import  "babel-polyfill";
// import  'core-js/fn/Map';
// import  'core-js/fn/set';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import loadable from './config/loadable';
const Home = loadable(()=>import('./components/PageIndex'));
// import Home from './components/PageIndex';
import { Provider } from 'react-redux';
import store from './store';
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './App.css';
moment.locale('zh-cn');

const App = () => (
    <Provider store={store}>
        <LocaleProvider locale={zh_CN}>
            <Router>
                <Switch>
                    <Route path="/" component={Home} />
                </Switch>
            </Router>
        </LocaleProvider>
    </Provider>
);

export default App;
