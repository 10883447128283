import { wrapServer2 } from '../config/axios';
import api from '../config/api';

export enum ACTION_TYPE {
  GET_BLOCK = 'GET_BLOCK',
  GET_EPOCHS = 'GET_EPOCHS',
  GET_CURRENT_BLOCK = 'GET_CURRENT_BLOCK',
  GET_BLOCK_DETAIL = 'GET_BLOCK_DETAIL',
  GET_TRANSACTION_DETAIL = 'GET_TRANSACTION_DETAIL',
}

export const getBlock = (data) => {
  return {
    type: ACTION_TYPE.GET_BLOCK,
    promise: wrapServer2({
      method: 'get',
      url: api.getBlock,
    }),
  };
};

export const getEpochs = (data) => {
  return {
    type: ACTION_TYPE.GET_EPOCHS,
    promise: wrapServer2({
      method: 'get',
      url: api.getEpochs,
    }),
  }
}

export const getCurrentBlock = (data) => {
  return {
    type: ACTION_TYPE.GET_CURRENT_BLOCK,
    promise: wrapServer2({
      method: 'get',
      url: api.getCurrentBlock,
    }),
  }
}

export const getBlockDetail = (data) => {
  const { block } = data;
  return {
    type: ACTION_TYPE.GET_BLOCK_DETAIL,
    promise: wrapServer2({
      method: 'get',
      url: `${api.getBlockDetail}/${block}`,
    }),
  }
}

export const getTransactionDetail = (data) => {
  const { txId } = data;
  return {
    type: ACTION_TYPE.GET_TRANSACTION_DETAIL,
    promise: wrapServer2({
      method: 'get',
      url: `${api.getTransactionDetail}/${txId}`,
    }),
  }
}

export default {
  ACTION_TYPE
};