import { handle } from "redux-pack";
import { blockChain } from "../actions";

const { ACTION_TYPE } = blockChain;

const initStates = {
  loading: {},
  blocks: {
    blocks: []
  },
  epochs: {
    txs: []
  },
  currentBlock: {
    currentBlock: {
      currentTime: 0,
      epoch: 0,
      slot: 0,
      transactions: 0
    }
  },
  blockDetail: {
    block: [
      {
        height: "0-0",
        hash: "",
        nextHash: "",
        prevHash: "",
        rootHash: "",
        timestamp: "",
        transaction_count: ""
      }
    ]
  },
  transactionDetail: {
    tx: [
      {
        timestamp: '',
        block: '0-0',
        cmds: '',
        gas: '',
        txId: '',
        tx: {
          tx: {
            payload: {
              exec: {
                code: ''
              }
            },
            nonce: '',
            meta: {
              sender: "",
              gasPrice: '',
              gasLimit: '',
              creationTime: ''
            }
          }
        }
      }
    ]
  }
};

export default (state = initStates, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPE.GET_BLOCK:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: { ...prevState.loading, [ACTION_TYPE.GET_BLOCK]: true }
        }),
        failure: prevState => ({ ...prevState, blocks: state.blocks }),
        success: prevState => ({ ...prevState, blocks: payload.data }),
        finish: prevState => ({
          ...prevState,
          loading: { ...prevState.loading, [ACTION_TYPE.GET_BLOCK]: false }
        })
      });
    case ACTION_TYPE.GET_EPOCHS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: { ...prevState.loading, [ACTION_TYPE.GET_EPOCHS]: true }
        }),
        failure: prevState => ({ ...prevState, epochs: state.epochs }),
        success: prevState => ({ ...prevState, epochs: payload.data }),
        finish: prevState => ({
          ...prevState,
          loading: { ...prevState.loading, [ACTION_TYPE.GET_EPOCHS]: false }
        })
      });
    case ACTION_TYPE.GET_CURRENT_BLOCK:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            [ACTION_TYPE.GET_CURRENT_BLOCK]: true
          }
        }),
        failure: prevState => ({
          ...prevState,
          currentBlock: state.currentBlock
        }),
        success: prevState => ({ ...prevState, currentBlock: payload.data }),
        finish: prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            [ACTION_TYPE.GET_CURRENT_BLOCK]: false
          }
        })
      });

    case ACTION_TYPE.GET_BLOCK_DETAIL:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            [ACTION_TYPE.GET_BLOCK_DETAIL]: true
          }
        }),
        failure: prevState => ({
          ...prevState,
          blockDetail: state.blockDetail
        }),
        success: prevState => ({ ...prevState, blockDetail: payload.data }),
        finish: prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            [ACTION_TYPE.GET_BLOCK_DETAIL]: false
          }
        })
      });

      case ACTION_TYPE.GET_TRANSACTION_DETAIL:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            [ACTION_TYPE.GET_TRANSACTION_DETAIL]: true
          }
        }),
        failure: prevState => ({
          ...prevState,
          transactionDetail: state.transactionDetail
        }),
        success: prevState => ({ ...prevState, transactionDetail: payload.data }),
        finish: prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            [ACTION_TYPE.GET_TRANSACTION_DETAIL]: false
          }
        })
      });

    default:
      return state;
  }
};
